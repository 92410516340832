<template>
  <van-popup
    v-model="showVisible"
    position="bottom"
    @close="cancel"
    @closed="$emit('closed')"
    title="请选择"
  >
    <div class="picker-popup-top">
      <slot name="top"></slot>
    </div>
    <van-picker
      v-myLoad="loading"
      show-toolbar
      :columns="datas"
      @confirm="confirm"
      @cancel="cancel"
      :value-key="valueKey"
      :default-index="defaultIndex"
      v-if="datas.length"
      @change="directConfirm"
      :title="title"
    />
    <div class="empty">
      <slot name="empty"></slot>
    </div>
  </van-popup>
</template>

<script>
import { objToArr } from '@/utils/utils'
export default {
  name: 'picker',
  // 会触发的事件
  // confirm ([value,index])
  // cancel
  props: {
    show: {
      // 需加.sync修饰符
      type: Boolean,
      default: false,
    },
    columns: {
      // required: true,
      type: [Object, Array],
    },
    valueKey: {
      type: String,
      default: 'text',
    },
    keyName: {
      type: String,
      default: 'key',
    },
    curKey: {
      default: undefined,
    },
    disabledKeys: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      showVisible: this.show,
      datas: [],
    }
  },
  computed: {
    defaultIndex() {
      let i = 0
      this.datas.filter((item, index) => {
        if (item[this.keyName] == this.curKey) {
          i = index
          return true
        } else {
          return false
        }
      })
      return i
    },
  },
  watch: {
    show() {
      this.showVisible = this.show
    },
    showVisible(val) {
      this.$emit('update:show', val)
    },
    columns: {
      deep: true,
      handler() {
        this.dataDeal()
      },
    },
  },
  created() {
    this.dataDeal()
  },
  methods: {
    directConfirm(obj, values, index) {
      this.confirm(values, index)
    },
    confirm(values, index) {
      this.$emit('update:show', false)
      this.$emit('confirm', [values, index])
    },
    cancel() {
      this.$emit('update:show', false)
      this.$emit('cancel')
    },
    dataDeal() {
      if (Array.isArray(this.columns)) {
        this.datas = JSON.parse(JSON.stringify(this.columns))
      } else {
        this.datas = objToArr(this.columns, this.keyName, this.valueKey)
      }

      this.datas.forEach((item) => {
        //  && item[this.keyName] == this.curKey
        if (this.disabledKeys.includes(item[this.keyName])) {
          item['disabled'] = true
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.picker-popup-top {
  margin-top: 1rem;
}
.empty {
  display: flex;
  justify-content: center;
  padding: 1rem;
  color: @mainColor;
}
</style>
